import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Box, Dialog, DialogContent } from "@material-ui/core"; // Corrected import

import { makeStyles, styled } from "@material-ui/core/styles";

import logo2 from "./logo2.png";
import i18next from "../../framework/src/languageSupport/i18n.web";
import EnglishLogo from "./UAE_MOEcon_brandmark_Horizontal_CMYK_E_Artboard.png";
import ArabicLogo from "./UAE_MOEcon_brandmark_Horizontal_CMYK_A_Artboard.png";
import Loader from "../../../packages/components/src/Loader.web";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  logoLeft: {
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  navBar: {
    backgroundColor: "white", // Set navbar background to white
  },
  logoContainer: {
    display: "flex",
    alignItems: "center", // Center vertically
  },
  typographyContainer: {
    display: "flex",
    flexDirection: "column", // Stack typography vertically
  },
}));

const TopNav = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showLoader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("preferredLanguage") || "English"
  );

  const handleLanguageChange = (lang) => {
    setLoader(true);
    setLanguage(lang);
    localStorage.setItem("preferredLanguage", lang);
    const i8lang = lang == "English" ? "en" : "ar";
    i18next.changeLanguage(i8lang);
    window.location.reload();
    setOpenDialog(false);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("preferredLanguage");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }

    const i8lang = storedLanguage == "English" ? "en" : "ar";
    i18next.changeLanguage(i8lang);
  }, []);

  return (
    <Box>
      <HeaderStyles
        language={language}
        position="static"
        className={classes.navBar}
      >
        <Box
          className={classes.toolbar}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            direction: "ltr", // Force LTR layout for the whole navbar, even in RTL
          }}
        >
          <Box
            style={{
              display: "flex",
              height: "109px",
              padding: "10px 10px 10px 0px",
              justifyContent: "flex-start",
              gap: "10px",
              borderRadius: "4px",
              flexDirection: "row", // Keeps it left-aligned in both LTR and RTL
            }}
          >
            <img
              src={language === "English" ? EnglishLogo : ArabicLogo}
              alt="Logo Left"
              style={{ height: "109px", flexShrink: "0" }}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <img
              src={logo2}
              alt="Logo 2"
              style={{ width: "74.67px", height: "80px" }}
            />
          </Box>
        </Box>

        <Toolbar
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "96%",
            background: "var(--Primary-AE-Black-50, #F7F7F7)",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.0961 8.10692L10.8461 2.21005C10.843 2.20738 10.8402 2.20451 10.8375 2.20145C10.6074 1.99218 10.3075 1.87622 9.99648 1.87622C9.68545 1.87622 9.38558 1.99218 9.15547 2.20145L9.14688 2.21005L2.90391 8.10692C2.77656 8.22402 2.67491 8.36628 2.60538 8.5247C2.53586 8.68311 2.49997 8.85424 2.5 9.02724V16.2499C2.5 16.5814 2.6317 16.8994 2.86612 17.1338C3.10054 17.3682 3.41848 17.4999 3.75 17.4999H7.5C7.83152 17.4999 8.14946 17.3682 8.38388 17.1338C8.6183 16.8994 8.75 16.5814 8.75 16.2499V12.4999H11.25V16.2499C11.25 16.5814 11.3817 16.8994 11.6161 17.1338C11.8505 17.3682 12.1685 17.4999 12.5 17.4999H16.25C16.5815 17.4999 16.8995 17.3682 17.1339 17.1338C17.3683 16.8994 17.5 16.5814 17.5 16.2499V9.02724C17.5 8.85424 17.4641 8.68311 17.3946 8.5247C17.3251 8.36628 17.2234 8.22402 17.0961 8.10692ZM16.25 16.2499H12.5V12.4999C12.5 12.1684 12.3683 11.8504 12.1339 11.616C11.8995 11.3816 11.5815 11.2499 11.25 11.2499H8.75C8.41848 11.2499 8.10054 11.3816 7.86612 11.616C7.6317 11.8504 7.5 12.1684 7.5 12.4999V16.2499H3.75V9.02724L3.75859 9.01942L10 3.12489L16.2422 9.01786L16.2508 9.02567L16.25 16.2499Z"
                fill="#5F646D"
              />
            </svg>
            <Button
              style={{
                color: "var(--Primary-AE-Black-800, #232528)",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "28px",
                fontFamily: "Cairo",
              }}
              onClick={() => navigate("/")}
            >
              {i18next.t("topnavbar.homeButtonText")}
            </Button>
          </Box>
          <Button
            style={{
              color: "var(--Primary-AE-Black-800, #232528)",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "28px",
              marginLeft: language === "English" && "auto",
              marginRight: language !== "English" && "auto",
            }}
            onClick={() => setOpenDialog(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM20.2153 11.25H16.4813C16.3491 8.58187 15.5184 6.04969 14.1263 4.03125C15.7568 4.469 17.2142 5.39535 18.3027 6.68582C19.3912 7.97629 20.0587 9.56903 20.2153 11.25ZM12 20.2397C10.2441 18.3394 9.17907 15.6459 9.02157 12.75H14.9784C14.8209 15.6441 13.7559 18.3394 12 20.2397ZM9.02157 11.25C9.17907 8.35594 10.2403 5.66062 12 3.76031C13.7559 5.66062 14.8209 8.35406 14.9784 11.25H9.02157ZM9.87375 4.03125C8.48157 6.04969 7.65094 8.58187 7.51875 11.25H3.78469C3.94133 9.56903 4.60876 7.97629 5.69728 6.68582C6.7858 5.39535 8.24325 4.469 9.87375 4.03125ZM3.78469 12.75H7.51875C7.65094 15.4181 8.48157 17.9503 9.87375 19.9688C8.24325 19.531 6.7858 18.6046 5.69728 17.3142C4.60876 16.0237 3.94133 14.431 3.78469 12.75ZM14.1263 19.9688C15.5184 17.9475 16.3491 15.4153 16.4813 12.75H20.2153C20.0587 14.431 19.3912 16.0237 18.3027 17.3142C17.2142 18.6046 15.7568 19.531 14.1263 19.9688Z"
                fill="#92722A"
              />
            </svg>{" "}
          </Button>
        </Toolbar>
      </HeaderStyles>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Centers the content vertically
            alignItems: "center", // Centers the content horizontally
            height: "291px",
            width: "343px",
          }}
        >
          <Button
            style={{
              color: "var(--Primary-AE-Black-800, #232528)",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "26px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              marginBottom: "10px",
            }}
            onClick={() => handleLanguageChange("English")}
            color="primary"
          >
            English
          </Button>

          <Button
            style={{
              color: "#CBA344",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "26px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
            onClick={() => handleLanguageChange("Arabic")}
            color="primary"
          >
            العربية
          </Button>
        </DialogContent>
      </Dialog>

      <Loader loading={showLoader} />
    </Box>
  );
};

const HeaderStyles = styled(AppBar)(({ language }) => ({
  display: "flex",
  width: "100%", // Set width to 100% for responsiveness
  height: "203px",
  flexDirection: "column",
  alignItems: language === "English" ? "flex-start" : "flex-end", // Align items based on the language
}));

export default TopNav;
